import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Box, { BoxProps } from '@mui/material/Box'

import { useTranslation } from "react-i18next";

import Icon from 'src/@core/components/icon'

import MediaStorage from 'src/pages/courses/media-storage'

import { MediaVariant } from 'src/types/files/media'

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(2.5)}`,
  right: `-${theme.spacing(2.5)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.customColors.cardBg,
  boxShadow: `0 2px 4px 0 ${theme.colorWithOpacity(theme.palette.grey[500], 30)}`,
  cursor: 'pointer'
}))

const ModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'calc(100% - 224px)',
  width: 'calc(100% - 340px)',
  border: 0,
  outline: 0,
  borderRadius: theme.spacing(1.5),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity('#4B465C', 40)}`,
  backgroundColor: theme.palette.customColors.cardBg,
  [theme.breakpoints.down('md')]: {
    width: '93%',
    height: '90%'
  }
}))

const ModalBody = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  height: 'calc(100% - 68px)',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 130px)'
  }
}))

const ModalFooter = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  padding: theme.spacing(1, 6, 5)
}))

type MentorcloudModalProps = {
  isOpen: boolean
  mediaType: MediaVariant
  onChoose: () => void
  onClose: () => void
  onCancel: () => void
}

const MentorcloudModal = ({ isOpen, mediaType, onChoose, onClose, onCancel }: MentorcloudModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <IconBox>
          <Icon icon='tabler:x' onClick={onClose} />
        </IconBox>
        <ModalBody>
          <MediaStorage isModal={true} mediaType={mediaType} onChoose={onChoose}></MediaStorage>
        </ModalBody>
        <ModalFooter sx={{ padding: '15px 32px', height: '68px', flexDirection: { xs: 'column', md: 'row' } }}>
          <Button variant='tonal' onClick={onCancel}>
            {t('ACTION.cancel')}</Button>
          <Button variant='contained' color='primary' onClick={onChoose}>
            {t('extracted.choose_selected')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default MentorcloudModal
